import { BodyPrimary, BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getCountDown } from '@/utils/countDown';
import { JSX, useCallback, useEffect, useRef, useState } from 'react';
import { safeFromUnixTime, shortFormatDateYearless } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import styled from 'styled-components';

type Props = {
    className?: string;
    date: number;
    onlyShowCountdown?: boolean;
    showDate?: boolean;
    showDateWithoutYear?: boolean;
    showOnlyYear?: boolean;
    timedPlus?: boolean;
    usePrimary?: boolean;
};

const CatalogDate = ({
    className = '',
    date = 0,
    onlyShowCountdown = false,
    showDate = true,
    showDateWithoutYear = false,
    showOnlyYear = false,
    timedPlus,
    usePrimary = false,
}: Props) => {
    let timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
    let dateSpanRef = useRef<HTMLSpanElement>(null);
    let { countDown: openingCountDown, dateFormat: openingDateFormat } = getCountDown(date);
    const [countDown, setCountDown] = useState(openingCountDown);
    const [dateFormat, setDateFormat] = useState(openingDateFormat);

    const updateCountdown = useCallback(() => {
        // we check to see if the component still exists so that we dont try and set state on an unmounted component
        if (dateSpanRef) {
            const { countDown, dateFormat } = getCountDown(date);
            setCountDown(countDown);
            setDateFormat(dateFormat);
            // this causes a rerender on a 30 sec schedule, if countdown is true
            if (countDown) {
                timeoutRef.current = setTimeout(updateCountdown, 30000);
            }
        }
    }, [date]);

    useEffect(() => {
        return () => {
            // cleans up the timer when the component unmounts
            clearTimeout(timeoutRef.current);
        };
    }, [countDown, updateCountdown]);

    // if a date is passed, set the countdown to trigger rerender on schedule
    useEffect(() => {
        if (date) {
            updateCountdown();
        }
    }, [date, updateCountdown]);

    const displayDate = countDown ? (
        <CountdownSpan color="critical">
            <FormattedMessage
                id={timedPlus ? 'closingIn' : 'left'}
                values={{ formattedDate: dateFormat }}
            />
        </CountdownSpan>
    ) : (
        <span>{dateFormat}</span>
    );

    if (!showDate || (!countDown && onlyShowCountdown)) {
        return null;
    }

    const year = safeFromUnixTime(date).getFullYear();

    const dateWithoutYearText = shortFormatDateYearless(date);

    let dateText: JSX.Element = displayDate;

    if (showOnlyYear) {
        dateText = <span>{year}</span>;
    } else if (showDateWithoutYear) {
        dateText = (
            <span>
                {timedPlus ? (
                    <FormattedMessage
                        id="startsIn"
                        values={{ formattedDate: dateWithoutYearText }}
                    />
                ) : (
                    dateWithoutYearText
                )}
            </span>
        );
    }

    if (usePrimary) {
        return (
            <BodyPrimary
                className={className}
                data-testid="date-span"
                ref={dateSpanRef}
            >
                {dateText}
            </BodyPrimary>
        );
    }

    return (
        <BodySecondary
            className={className}
            data-testid="date-span"
            ref={dateSpanRef}
            suppressHydrationWarning
        >
            {dateText}
        </BodySecondary>
    );
};

export default CatalogDate;

const CountdownSpan = styled(BodySecondary)`
    &&& {
        text-transform: capitalize;
    }
`;

CountdownSpan.displayName = 'CountdownSpan';
