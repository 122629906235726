import * as React from 'react';
import { getCatalogTitle } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getLiveBiddingUrl } from '@/utils/urls';
import { getLocale } from '@/redux/modules/intl';
import { isUiMobile } from '@/redux/modules/browser';
import { popupCenter } from '@/utils/popupCenter';
import { useAppSelector } from '@/redux/hooks';
import { useNavigate } from 'react-router-dom';
import isEqual from 'react-fast-compare';

type Props = {
    catalogId: number;
    children: React.ReactNode;
    onNavigate?: () => void;
};

const WIDTH = 1248;
const HEIGHT = 800;

/**
 * Turn any react component into a button that opens the bidding console in a new window.
 */
const BidConsoleOpener = ({ catalogId = 0, children = '', onNavigate }: Props) => {
    const navigate = useNavigate();
    const locale = useAppSelector(getLocale);
    const mobile = useAppSelector(isUiMobile);
    const title = useAppSelector((state) => getCatalogTitle(state, catalogId));

    const localePath = locale === 'en-gb' ? `/${locale}` : '';

    const openBidConsole = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            onNavigate?.();
            /**
             * Enable either mobile devices or holding down the shift key to open the console in the same window.
             */
            if (Boolean(mobile) || event.shiftKey) {
                navigate(getLiveBiddingUrl(catalogId));
            } else {
                popupCenter({
                    height: HEIGHT,
                    title,
                    url: localePath + getLiveBiddingUrl(catalogId),
                    width: WIDTH,
                });
            }
        },
        [onNavigate, mobile, navigate, catalogId, title, localePath]
    );

    if (React.isValidElement(children)) {
        return React.cloneElement(React.Children.only(children), {
            /* @ts-expect-error `children` could be anything */
            onClick: openBidConsole,
        });
    }

    return null;
};

export default React.memo<Props>(BidConsoleOpener, isEqual);
