import * as React from 'react';
import { FormattedNumber } from '@liveauctioneers/hammer-ui-core/intl';
import { getCurrencyConversion } from '@/redux/modules/currency';
import { useAppSelector } from '@/redux/hooks';
import FormattedPreferredCurrency from './FormattedPreferredCurrency';
import styled from 'styled-components';

type Props = {
    /** For rendering children */
    children?: any;
    /** For passing in custom styles */
    className?: any;
    /** When true, will display the converted currency in h3 font */
    conversionH3?: boolean;
    /** when true, does not render the grey background for the converted currency */
    conversionNoBackground?: boolean;
    /** When true, will display the converted currency according to the bidders set preference */
    convert?: boolean;
    /** When true, does not render any cent digits */
    noFraction?: boolean;
    /** For passing in custom styles to the converted currency display */
    preferredCurrencyclassName?: any;
    /** The denomination of the source currency e.g. 'USD' or 'GBP' */
    sourceCurrency: string;
    /** The source currency amount in dollars and cents, this value will be converted to the user's preferred currency */
    value: number;
};

const FormattedCurrency = ({
    children,
    className,
    conversionH3 = false,
    conversionNoBackground = false,
    convert = false,
    noFraction = false,
    preferredCurrencyclassName,
    sourceCurrency = 'USD',
    value,
    ...rest
}: Props) => {
    const currencyConversion = useAppSelector((state) => getCurrencyConversion(state, sourceCurrency));
    const numFractionDigits = noFraction ? 0 : currencyConversion?.fractionSize || 2;

    return (
        <StyledFormattedCurrency
            className={className}
            key="formatted-currency-container"
            {...rest}
        >
            <FormattedNumber
                currency={sourceCurrency}
                key={`formatted-currency-${sourceCurrency}-${value}`}
                maximumFractionDigits={numFractionDigits}
                minimumFractionDigits={numFractionDigits}
                style="currency"
                value={value}
            >
                {children}
            </FormattedNumber>
            {convert && (
                <StyledFormattedPreferredCurrency
                    className={preferredCurrencyclassName}
                    h3={conversionH3}
                    noBackground={conversionNoBackground}
                    noFraction={noFraction}
                    sourceCurrency={sourceCurrency}
                    sourceValue={value}
                />
            )}
        </StyledFormattedCurrency>
    );
};

const StyledFormattedCurrency = styled.span`
    display: inline-flex;
    align-items: center;
`;

const StyledFormattedPreferredCurrency = styled(FormattedPreferredCurrency)`
    margin-left: 7px;
`;

export default React.memo<Props>(FormattedCurrency);
