import { AuctionStatus } from '@/types/LiveAuctionState';
import {
    getItemModelBuyNowAvailable,
    getItemModelCatalogStatus,
    getItemModelSlug,
    getItemModelUrl,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { getPriceResultsUrl, isCatalogPage, isPriceGuidePage, isPriceResultsPage } from '@/utils/urls';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';

/**
 * useItemOrPriceResultLink - uses itemId to generate a slug from the title and
 * changes up the link returned based on current page and logged in status to funnel search
 * bot crawling traffic and results. For SEO sold item optimization PT-2275
 * @param itemId item to obtain title
 * @param slugOnly omits the itemId query string from returned URL
 * @returns string relative path to final result page
 */
export const useItemOrPriceResultLink = (itemId: number, slugOnly: boolean = false) => {
    const { pathname } = useLocation();
    const catalogStatus = useAppSelector((state) => getItemModelCatalogStatus(state, itemId));
    const slug = useAppSelector((state) => getItemModelSlug(state, itemId));
    const isLoggedIn = useAppSelector((state) => isUserLoggedIn(state));
    const isPriceResult = isPriceResultsPage(pathname) || isCatalogPage(pathname) || isPriceGuidePage(pathname);
    let itemUrl = useAppSelector((state) => getItemModelUrl(state, itemId));
    const buyNow = useAppSelector((state) => getItemModelBuyNowAvailable(state, itemId));
    if (catalogStatus === AuctionStatus.Done && !isLoggedIn && isPriceResult && !buyNow) {
        itemUrl = slugOnly ? getPriceResultsUrl(slug) : getPriceResultsUrl(slug, itemId);
    }
    return itemUrl;
};
