import * as React from 'react';
import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { CompleteAccountModalContext, ModalContext } from '@/redux/modules/modal';
import { CurrencyCircleDollar } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { EventContext } from '@/redux/modules/analytics';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { isUserAtLeastPartiallyRegistered } from '@/redux/modules/account/user/user.selectors';
import { LinkBody } from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import { useAppSelector } from '@/redux/hooks';
import noop from '@/utils/noop';
import ShouldLogInContainer from '@/components/ShouldLogIn/ShouldLogInContainer';

type Props = {
    children?: React.ReactNode;
    className?: string;
    hideIcon?: boolean;
    itemId: number;
    // Here for disabling hover styles on parent when component is an active one
    onMouseEnter?: React.MouseEventHandler<HTMLSpanElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLSpanElement>;
    priceGatewayIcon?: boolean;
    runFunction?: () => void;
    runFunctionArgument?: any[] | number | string | any;
    showChildren?: boolean;
};

/**
 * 1. If logged out, show See Sold Price, else show sold price.
 * 2. Clicking See Sold Price prompts sign-up modal (the one with "What's It Worth?" title)
 * 3. See Sold Price appears on item cards and sold item page (at top and in bid box down toward the page)
 */
const ItemPriceGatewayContainer = (props: Props) => {
    const {
        children,
        className,
        hideIcon = false,
        itemId,
        onMouseEnter,
        onMouseLeave,
        priceGatewayIcon,
        runFunction = noop,
        runFunctionArgument,
        showChildren,
    } = props;

    const userAtLeastPartiallyRegistered = useAppSelector(isUserAtLeastPartiallyRegistered);

    const modalContext = React.useMemo<ModalContext>(
        () => ({
            eventContext: EventContext.seeSoldPrice,
            id: itemId,
            itemId,
            type: CompleteAccountModalContext.SeeSoldPrice,
        }),
        [itemId]
    );

    const forceSignUp = !userAtLeastPartiallyRegistered;

    const content =
        userAtLeastPartiallyRegistered || showChildren ? (
            children
        ) : (
            <span className={className}>
                <LinkBody
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    {!hideIcon && (
                        <CurrencyCircleDollar
                            className={cn('mr-2xs', {
                                'align-text-bottom': priceGatewayIcon,
                            })}
                            size="sm"
                        />
                    )}
                    <FormattedMessage id="price_gateway_card" />
                </LinkBody>
            </span>
        );

    return (
        <ShouldLogInContainer
            forceSignUp={forceSignUp}
            modalContext={modalContext}
            requireLogin={false}
            requireUserAtLeastPartiallyRegistered
            runFunction={runFunction}
            runFunctionArgument={runFunctionArgument}
        >
            {content}
        </ShouldLogInContainer>
    );
};

export default React.memo<Props>(ItemPriceGatewayContainer);
