import { differenceInMinutes, formatDistanceStrict } from 'date-fns';
import { safeFromUnixTime, shortFormatDate } from '@liveauctioneers/caterwaul-components/lib/utils/dates';

export const COUNTDOWN_CUTOFF_DAYS_IN_MINUTES = 10080; // 7 days

export const showCountDownBasedOnMinimumDate = (saleStartTs: number) => {
    const now = new Date();
    const upcoming = safeFromUnixTime(saleStartTs);
    const totalDays = differenceInMinutes(upcoming, now);

    return totalDays <= COUNTDOWN_CUTOFF_DAYS_IN_MINUTES;
};

type CountdownInfo = {
    countDown: boolean;
    dateFormat: string;
};

export const getCountDown = (auctionTs: number): CountdownInfo => {
    const now = Date.now();
    const upcoming = safeFromUnixTime(auctionTs);
    let countDown = false;

    let dateFormat = shortFormatDate(upcoming);

    const totalMinutesUntil = differenceInMinutes(upcoming, now);
    if (totalMinutesUntil > 0 && totalMinutesUntil <= COUNTDOWN_CUTOFF_DAYS_IN_MINUTES) {
        // date-fns has no option for abbreviations so we'll do it here
        dateFormat = formatDistanceStrict(now, upcoming);
        dateFormat = dateFormat
            .replace('hours', 'hrs')
            .replace('hour', 'hr')
            .replace('minutes', 'mins')
            .replace('minute', 'min');
        countDown = true;
    }

    return {
        countDown,
        dateFormat,
    };
};
