// Credit to https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen

type PopupCenterProps = {
    height: number;
    title: string;
    url: string;
    width: number;
};

export const popupCenter = ({ height, title, url, width }: PopupCenterProps) => {
    // Fixes dual-screen position                         Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    /*eslint-disable */
    const windowWidth = window.innerWidth
        ? window.innerWidth
        : document.documentElement?.clientWidth
          ? document.documentElement.clientWidth
          : screen.width;
    const windowHeight = window.innerHeight
        ? window.innerHeight
        : document.documentElement?.clientHeight
          ? document.documentElement.clientHeight
          : screen.height;

    /*eslint-enable */

    const systemZoom = windowWidth / window.screen.availWidth;
    const left = Math.floor((windowWidth - width) / 2 / systemZoom + dualScreenLeft);
    const top = Math.floor((windowHeight - height) / 2 / systemZoom + dualScreenTop);

    const newWindow = window.open(
        url,
        title,
        ['status=no', 'menubar=no', `width=${width}`, `height=${height}`, `top=${top}`, `left=${left}`].join(',')
    );

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
    return newWindow;
};
