import * as React from 'react';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { FormattedNumber } from '@liveauctioneers/hammer-ui-core/intl';
import { getCurrencyConversion } from '@/redux/modules/currency';
import { preferredCurrencySelector } from '@/redux/modules/bidder/preferences/bidderPreferences.selectors';
import { useAppSelector } from '@/redux/hooks';
import styled from 'styled-components';

type Props = {
    /** For passing in custom styles */
    className?: any;
    /** For passing in color white to the text component */
    colorWhite?: boolean;
    /** For passing in custom styles to the currency display */
    currencyClassName?: any;
    /** Renders the text as h3 when true */
    h3?: boolean;
    /** when true, does not render the grey background */
    noBackground?: boolean;
    /** when true, does not render any cent digits */
    noFraction?: boolean;
    /** The denomination of the source currency e.g. 'USD' or 'GBP' */
    sourceCurrency: string;
    /** The source currency amount, this value will be converted to the user's preferred currency */
    sourceValue: number;
    /** When true, will render () around the converted currency. This is used for the message scroller's unique display */
    wrapWithParens?: boolean;
};

/**
 * Displays the user preferred currency, converted from the input currency
 */
const FormattedPreferredCurrency = ({
    className,
    colorWhite = false,
    currencyClassName,
    h3 = false,
    noBackground = false,
    noFraction = false,
    sourceCurrency = 'USD',
    sourceValue,
    wrapWithParens = false,
}: Props) => {
    const preferredCurrency = useAppSelector(preferredCurrencySelector);
    const targetCurrency = useAppSelector((state) => getCurrencyConversion(state, preferredCurrency));

    const sourceCurrencyConversion = useAppSelector((state) => getCurrencyConversion(state, sourceCurrency));

    // Show nothing if the source/target currencies are the same or don't exist in the currency conversion table
    if (
        !Boolean(targetCurrency) ||
        !Boolean(sourceCurrencyConversion) ||
        sourceCurrency === targetCurrency?.currencyCode
    ) {
        return null;
    }

    // Convert source currency to USD, we do this because we only store conversions from usd, so we always need this.
    const sourceInUSD = sourceValue / sourceCurrencyConversion?.conversionToUsd;

    // Convert from USD to target currency
    const valueInTargetCurrency = sourceInUSD * targetCurrency?.conversionToUsd;

    const fractionDigits = noFraction ? 0 : targetCurrency?.fractionSize;
    return (
        <PreferredCurrencyContainer className={className}>
            {wrapWithParens && '('}
            <ConvertedCurrency
                $noBackground={noBackground}
                className={currencyClassName}
            >
                <BodySecondary
                    className={cn(
                        // add ellipsis
                        'block overflow-hidden text-ellipsis whitespace-nowrap',
                        h3 && 'hui-text-h3',
                        colorWhite && 'text-text-inverse'
                    )}
                >
                    <FormattedNumber
                        currency={targetCurrency?.currencyCode}
                        key={`formatted-preferred-currency-${valueInTargetCurrency}`}
                        maximumFractionDigits={fractionDigits}
                        minimumFractionDigits={fractionDigits}
                        style="currency"
                        value={valueInTargetCurrency}
                    />
                </BodySecondary>
            </ConvertedCurrency>
            {wrapWithParens && ')'}
        </PreferredCurrencyContainer>
    );
};

export default React.memo<Props>(FormattedPreferredCurrency);

const PreferredCurrencyContainer = styled.div`
    display: flex;
    overflow: hidden;
`;

const ConvertedCurrency = styled.div<{ $noBackground: boolean }>`
    padding: 0 5px;
    background-color: ${(props) => !props.$noBackground && props.theme.colors.grey400};
`;
