import * as React from 'react';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getCatalogRegistration } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import { getItemSummary, getItemSummaryIsPlacingBid } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getUserIsInGoodStanding } from '@/redux/modules/account/user/user.selectors';
import { getWonItem } from '@/redux/modules/bidder/biddingInfoAll/bidderBiddingInfoAll.selectors';
import { H6 } from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import { useAppSelector } from '@/redux/hooks';
import styled, { css, useTheme } from 'styled-components';
import useIsNarrowerThan from '@/hooks/useIsNarrowerThan';

type Props = {
    alignBannerBottomWithGap?: boolean;
    alignBannerToBottom?: boolean;
    itemId: number;
};

const CardStatusBanner = ({ alignBannerBottomWithGap, alignBannerToBottom = false, itemId }: Props) => {
    const {
        breakpoints: { tabletNarrowWidthNum },
        colors: { grey100, red100, red200, shadowOverlay400, shadowOverlayGreen100, shadowOverlayRed200, steel, teal },
    } = useTheme();

    const isMobile = useIsNarrowerThan(tabletNarrowWidthNum + 1);

    let banner = null;
    let bannerOverlay = '';
    const isMyItemsPageNewLayoutChange = true;

    const {
        bidderHasBid,
        bidderHasHighBid,
        bidderWonItem,
        catalogId,
        isAvailable,
        isPassed,
        isPaused,
        isReserveMet,
        isTimedPlusAuction,
    } = useAppSelector((state) => getItemSummary(state, itemId));
    const bidderHasPlacedBid = useAppSelector((state) => getItemSummaryIsPlacingBid(state, itemId));

    const accountInGoodStanding = useAppSelector(getUserIsInGoodStanding);
    const bidderHasWon = useAppSelector((state) => getWonItem(state, itemId)) || bidderWonItem;

    const { blockedByAuctioneer, declined, pending, suspended } = useAppSelector((state) =>
        getCatalogRegistration(state, catalogId)
    );

    if (suspended || !accountInGoodStanding) {
        banner = <FormattedMessage id="account_suspended" />;
        bannerOverlay = shadowOverlay400;
    } else if (blockedByAuctioneer || declined) {
        banner = <FormattedMessage id="registration_declined" />;
        bannerOverlay = shadowOverlay400;
    } else if (isAvailable) {
        if (bidderHasHighBid && isReserveMet) {
            if (pending) {
                if (isMyItemsPageNewLayoutChange && isMobile) {
                    banner = (
                        <StyledUppercaseBannerText $white>
                            <FormattedMessage id="pending" />
                        </StyledUppercaseBannerText>
                    );
                    bannerOverlay = steel;
                } else {
                    banner = (
                        <StyledUppercaseBannerText $white>
                            <FormattedMessage id="bid_registration_pending" />
                        </StyledUppercaseBannerText>
                    );
                    bannerOverlay = steel;
                }
            } else {
                if (isMyItemsPageNewLayoutChange) {
                    banner = (
                        <StyledUppercaseBannerText $white>
                            <FormattedMessage id="leading" />
                        </StyledUppercaseBannerText>
                    );
                    bannerOverlay = teal;
                } else {
                    banner = <FormattedMessage id="you_are_leading" />;
                    bannerOverlay = shadowOverlayGreen100;
                }
            }
        } else if (bidderHasHighBid && !isReserveMet) {
            banner = (
                <StyledUppercaseBannerText $white>
                    <FormattedMessage id="reserve_not_met" />
                </StyledUppercaseBannerText>
            );
            bannerOverlay = red100;
        } else if (!bidderHasHighBid && bidderHasBid && !bidderHasPlacedBid) {
            if (isMyItemsPageNewLayoutChange) {
                banner = (
                    <StyledUppercaseBannerText $white>
                        <FormattedMessage id="outbid" />
                    </StyledUppercaseBannerText>
                );
                bannerOverlay = red200;
            } else {
                banner = <FormattedMessage id="outbid" />;
                bannerOverlay = red100;
            }
        }
    } else if (bidderHasWon && !isPassed) {
        if (isMyItemsPageNewLayoutChange) {
            banner = (
                <StyledH6 $white>
                    <FormattedMessage id="you_won_new_banner" />
                </StyledH6>
            );
            bannerOverlay = teal;
        } else {
            banner = <FormattedMessage id="you_won" />;
            bannerOverlay = shadowOverlayGreen100;
        }
    } else if (isPassed && !isTimedPlusAuction && pending) {
        banner = <FormattedMessage id="not_approved" />;
        bannerOverlay = shadowOverlay400;
    } else if (bidderHasBid && !isPassed) {
        if (isMyItemsPageNewLayoutChange) {
            banner = (
                <StyledH6 $white>
                    <FormattedMessage id="lost_new_banner" />
                </StyledH6>
            );
            bannerOverlay = grey100;
        } else {
            banner = <FormattedMessage id="lost" />;
            bannerOverlay = shadowOverlayRed200;
        }
    }

    if (!Boolean(banner) || (isTimedPlusAuction && isPaused)) {
        return null;
    }

    return (
        <BannerDiv
            $alignBannerBottomWithGap={alignBannerBottomWithGap}
            $alignBannerToBottom={alignBannerToBottom}
            data-testid="banner-div"
            overlay={bannerOverlay}
        >
            {banner}
        </BannerDiv>
    );
};

export default React.memo<Props>(CardStatusBanner);

const BannerDiv = styled.div<{
    $alignBannerBottomWithGap?: boolean;
    $alignBannerToBottom?: boolean;
    overlay;
}>`
    display: ${({ overlay }) => (overlay ? 'block' : 'none')};
    position: absolute;
    width: 100%;
    max-width: calc(100% - 16px);
    height: 22px;
    line-height: 22px;
    bottom: 20px;
    font-family: ${({ theme }) => theme.typography.fontSecondary};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.shadow200};
    cursor: default;
    user-select: none;
    letter-spacing: 0.5px;
    background-color: ${({ overlay }) => (overlay ? overlay : 'transparent')};
    text-align: center;
    z-index: 10;
    ${({ $alignBannerBottomWithGap, $alignBannerToBottom, theme }) => {
        if ($alignBannerBottomWithGap) {
            return css`
                bottom: 8px;

                @media (max-width: ${theme.breakpoints.tabletNarrowWidth}) {
                    max-width: 100%;
                    bottom: 0px;
                    left: 0px;
                }
            `;
        }

        if ($alignBannerToBottom) {
            return css`
                bottom: 0px;

                @media (max-width: ${theme.breakpoints.tabletNarrowWidth}) {
                    max-width: 100%;
                    bottom: 0px;
                }
            `;
        }
    }}

    @media print {
        display: none !important;
    }
`;

const StyledH6 = styled(H6)`
    font-weight: 500;
`;

const StyledUppercaseBannerText = styled(H6)`
    font-weight: 500;
    text-transform: uppercase;
`;
